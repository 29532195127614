.font-campaign-choose-plan {
  font-family: "campaign" !important;
}

*:focus {
  outline: none;
}
/* 
#desk-search::placeholder{
  font-family: 'synthese';
} */

.font-synthese-choose-plan {
  font-family: "synthese" !important;
  /* font-weight: 400 !important; */
}
/* #search{
  background-image: url('../../assets/search-icon.svg');
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 35px;
} */

@media screen and (max-width: 280px) {
  .listen-for-free {
    background: #d9de77;
    min-width: 50px !important;
    padding: 8px 18px;
    border-radius: 18px !important;
    /* border-top-left-radius: 50px; */
    font-size: xx-small !important;
  }
  .listen-for-free::after {
    border-width: 17px 17px 0 0 !important;
  }
  .header-wrapper {
    /* min-height: min-content; */
    height: 640px !important;
  }
  .description1 {
    width: 28% !important;
    font-size: 1rem !important;
    padding-left: 0.25rem !important;
  }
  .description2 {
    left: 30% !important;
  }
  .content-wrapper1 {
    padding-top: 1rem !important;
    font-size: small !important;
  }
  .content-wrapper2 {
    font-size: small !important;
  }
  .about-wrapper {
    margin-top: 2.5rem !important;
  }
  .about-wrapper-heading3 {
    padding-top: 4rem !important;
  }
  .about-header1,
  .about-header2,
  .about-header3 {
    font-size: smaller;
  }
  .about-description1,
  .about-description2,
  .about-description3 {
    font-size: x-small;
  }
  .free-user-description {
    font-size: small !important;
  }
  /* .card-container{
    top: 6rem;
  } */
}

@media (min-width: 281px) and (max-width: 320px) {
  .listen-for-free {
    background: #d9de77;
    min-width: 170px !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    /* border-top-left-radius: 50px; */
    font-size: small !important;
  }
  .header-wrapper {
    /* min-height: fit-content; */
    height: 660px !important;
  }
  .about-wrapper-heading3 {
    padding-top: 4rem !important;
  }
  .description1 {
    width: 23% !important;
    font-size: 1rem !important;
    padding-left: 0.25rem !important;
  }
  .description2 {
    left: 28% !important;
  }
  .free-user-description {
    font-size: medium;
  }
  /* .card-container{
    top: 6rem;
  } */
}

@media only screen and (min-width: 321px) and (max-width: 640px) {
  .listen-for-free {
    background: #d9de77;
    min-width: 170px !important;
    padding: 10px 20px !important;
    border-radius: 18px !important;
    /* border-top-left-radius: 50px; */
    font-size: small !important;
  }
  /* .header-wrapper {
    
    height: 585px !important;
  } */
  .description2 {
    left: 25.5% !important;
  }
  .description1 {
    width: 21% !important;
    padding-left: 1% !important;
  }
  /* .content-wrapper1, .content-wrapper2 {
    
  } */
}

@media only screen and (width: 540px) {
  .plan-caraousal {
    min-width: 968px !important;
  }
}

.listen-for-free {
  /* background: #d9de77;
  min-width: 300px;
  padding: 10px 20px;
  border-radius: 20px; */
  /* border-top-left-radius: 50px; */
  /* font-size: large;
  font-weight: bold;
  position: relative; */
  /* Changes */
  background: #fcd34d;
  min-width: 250px;
  padding: 8px 20px;
  border-radius: 25px;
  /* border-top-left-radius: 50px; */
  font-size: larger;
  font-weight: bold;
  position: relative;
}

.listen-for-free::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 0 0;
  border-color: #fff #d9de77;
}

html.dark .listen-for-free::after {
  border-color: #000 #d9de77;
}

@media only screen and (min-width: 640px) and (max-width: 800px) {
  #desk-search::placeholder {
    font-size: small;
  }
  #parent-search {
    width: 100% !important;
  }
  .search-wrapper {
    padding-right: 4%;
    padding-left: 4%;
  }
  .header-wrapper {
    padding-right: 4%;
    padding-left: 4%;
  }
  .description1 {
    font-size: 20px !important;
    width: 20% !important;
  }
  .description2 {
    font-size: 20px !important;
    left: 17% !important;
  }
  .content-wrapper-desk {
    font-size: small !important;
  }
  .about-header1-title,
  .about-header2-title {
    font-size: small !important;
  }
  .about-description1,
  .about-description2,
  .about-description3 {
    font-size: x-small !important;
    line-height: normal !important;
  }
  .about-description1-container,
  .about-description2-container,
  .about-description3-container {
    width: fit-content !important;
  }
  .hand-image {
    width: 60% !important;
    height: 110% !important;
  }
  .card-header-wrapper {
    padding-right: 4%;
    padding-left: 4%;
  }
  .plan-caraousal-wrapper {
    padding-right: 4%;
    padding-left: 4%;
  }
  .free-user {
    padding-right: 4%;
    padding-left: 4%;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
  #desk-search::placeholder {
    font-size: small !important;
    /* overflow-x: scroll !important; */
  }
  #parent-search {
    width: 100% !important;
  }
  .search-wrapper {
    padding-right: 12%;
    padding-left: 12%;
  }
  .header-wrapper {
    padding-right: 12%;
    padding-left: 12%;
  }
  .description1 {
    font-size: 22px !important;
    width: 20% !important;
  }
  .description2 {
    font-size: 22px !important;
    left: 23% !important;
  }
  .content-wrapper-desk {
    font-size: medium !important;
  }
  .about-header1-title,
  .about-header2-title {
    font-size: medium !important;
  }
  .about-description1,
  .about-description2,
  .about-description3 {
    font-size: small !important;
    line-height: normal !important;
  }
  .about-description1-container,
  .about-description2-container,
  .about-description3-container {
    width: fit-content !important;
  }
  .hand-image {
    width: 60% !important;
    height: 120% !important;
  }
  .card-header-wrapper {
    padding-right: 12%;
    padding-left: 12%;
  }
  .plan-caraousal-wrapper {
    padding-right: 12%;
    padding-left: 12%;
  }
  .free-user {
    padding-right: 12%;
    padding-left: 12%;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  #desk-search::placeholder {
    font-size: small !important;
    /* overflow-x: scroll !important; */
  }
  #parent-search {
    width: 100% !important;
  }
  .search-wrapper {
    padding-right: 14%;
    padding-left: 14%;
  }
  .header-wrapper {
    padding-right: 14%;
    padding-left: 14%;
  }
  .description1 {
    font-size: 25px !important;
    width: 20% !important;
  }
  .description2 {
    font-size: 25px !important;
    left: 24% !important;
  }
  .content-wrapper-desk {
    font-size: medium !important;
  }
  .about-header1-title,
  .about-header2-title {
    font-size: medium !important;
  }
  .about-description1,
  .about-description2,
  .about-description3 {
    font-size: smaller !important;
    line-height: normal !important;
  }
  .about-description1-container,
  .about-description2-container,
  .about-description3-container {
    width: fit-content !important;
  }
  .hand-image {
    width: 70% !important;
    height: 130% !important;
  }
  .card-header-wrapper {
    padding-right: 14%;
    padding-left: 14%;
  }
  .plan-caraousal-wrapper {
    padding-right: 5%;
    padding-left: 5%;
  }
  .free-user {
    padding-right: 14%;
    padding-left: 14%;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .search-wrapper {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
    justify-content: center !important;
  }
  .title1,
  .title2 {
    font-size: medium !important;
    line-height: 1rem !important;
  }
  input::placeholder {
    font-size: small !important;
  }
  .header-wrapper {
    padding-left: 3rem !important;
  }
  .description1,
  .description2 {
    font-size: 1.75rem !important;
  }
  .description1 {
    width: 23% !important;
  }
  .content-wrapper-desk {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }
  .about-header1-title,
  .about-header2-title {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .about-description1,
  .about-description2,
  .about-description3 {
    font-size: 0.85rem !important;
    line-height: 1.25rem !important;
  }
  .hand-image {
    width: 70% !important;
  }
  .card-header-wrapper {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .heading1,
  .heading2 {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }
  .description-main {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .plan-caraousal-wrapper {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .free-user {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .free-user-description {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .search-wrapper {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
    justify-content: center !important;
  }
  .title1,
  .title2 {
    font-size: larger !important;
    line-height: 1.25rem !important;
  }
  input::placeholder {
    font-size: 0.85rem !important;
  }
  .header-wrapper {
    padding-left: 3rem !important;
  }
  .description1,
  .description2 {
    font-size: 1.75rem !important;
  }
  .description1 {
    width: 18% !important;
    padding-left: 1% !important;
  }
  .description2 {
    left: 16.5% !important;
  }
  .content-wrapper-desk {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }
  .about-header1-title,
  .about-header2-title {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .about-description1,
  .about-description2,
  .about-description3 {
    font-size: 0.85rem !important;
    line-height: 1.25rem !important;
  }
  .hand-image {
    width: 60% !important;
  }
  .card-header-wrapper {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .heading1,
  .heading2 {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }
  .description-main {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .plan-caraousal-wrapper {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .free-user {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .free-user-description {
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: portrait) {
  .search-wrapper {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .title1,
  .title2 {
    font-size: larger !important;
    line-height: 1.25rem !important;
  }
  input::placeholder {
    font-size: smaller !important;
  }
  .header-wrapper {
    padding-left: 5rem !important;
  }
  .description1,
  .description2 {
    font-size: 1.75rem !important;
  }
  .description1 {
    width: 18% !important;
    padding-left: 1% !important;
  }
  .description2 {
    left: 19.5% !important;
  }
  .content-wrapper-desk {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }
  .about-header1-title,
  .about-header2-title {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .about-description1,
  .about-description2,
  .about-description3 {
    font-size: 0.85rem !important;
    line-height: 1.25rem !important;
  }
  .hand-image {
    width: 70% !important;
  }
  .card-header-wrapper {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .heading1,
  .heading2 {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }
  .description-main {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .plan-caraousal-wrapper {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .free-user {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .free-user-description {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}
@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .search-wrapper {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .title1,
  .title2 {
    font-size: larger !important;
    line-height: 1.25rem !important;
  }
  .description1 {
    width: 18% !important;
    padding-left: 1% !important;
  }
  .description2 {
    left: 24.5% !important;
  }
  .free-user {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
}
