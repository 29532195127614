/* .font-spectral{
  font-family: 'Spectral', serif;
} */

.font-synthese700 {
  font-family: "synthese" !important;
  /* font-weight: 700 !important; */
}

@media only screen and (min-width: 640px) and (max-width: 700px) {
  .banner-header {
    width: fit-content !important;
    position: absolute !important;
    left: 46% !important;
    top: 26% !important;
    align-items: flex-end !important;
  }
  .banner-heading1 {
    font-size: 24px !important;
    text-align: center !important;
    padding-left: 10% !important;
  }
  .banner-heading2 {
    font-size: 24px !important;
    width: 65% !important;
    text-align: center !important;
  }
}

@media only screen and (min-width: 701px) and (max-width: 749px) {
  .banner-header {
    width: fit-content !important;
    position: absolute !important;
    left: 51% !important;
    top: 24% !important;
    align-items: flex-end !important;
  }
  .banner-heading1 {
    font-size: 24px !important;
    text-align: center !important;
    padding-left: 10% !important;
  }
  .banner-heading2 {
    font-size: 24px !important;
    width: 65% !important;
    text-align: center !important;
  }
}
@media only screen and (min-width: 750px) and (max-width: 799px) {
  .banner-header {
    width: fit-content !important;
    position: absolute !important;
    left: 52% !important;
    top: 24% !important;
    align-items: flex-end !important;
  }
  .banner-heading1 {
    font-size: 26px !important;
    text-align: center !important;
    padding-left: 10% !important;
  }
  .banner-heading2 {
    font-size: 26px !important;
    width: 73% !important;
    text-align: center !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 849px) {
  .banner-header {
    width: fit-content !important;
    position: absolute !important;
    left: 52% !important;
    top: 24% !important;
    align-items: flex-end !important;
  }
  .banner-heading1 {
    font-size: 28px !important;
    text-align: center !important;
    padding-left: 10% !important;
  }
  .banner-heading2 {
    font-size: 28px !important;
    width: 73% !important;
    text-align: center !important;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1280px) {
  .banner-header {
    width: fit-content !important;
    position: absolute !important;
    left: 60% !important;
    top: 24% !important;
    align-items: flex-end !important;
  }
  .banner-heading1 {
    font-size: 38px !important;
    text-align: center !important;
    padding-left: 10% !important;
  }
  .banner-heading2 {
    font-size: 38px !important;
    width: 73% !important;
    text-align: center !important;
  }
}

@media only screen and (min-width: 950px) and (max-width: 1099px) {
  .banner-header {
    width: fit-content !important;
    position: absolute !important;
    left: 55% !important;
    top: 24% !important;
    align-items: flex-end !important;
  }
  .banner-heading1 {
    font-size: 35px !important;
    text-align: center !important;
    padding-left: 10% !important;
  }
  .banner-heading2 {
    font-size: 35px !important;
    width: 73% !important;
    text-align: center !important;
  }
}

@media only screen and (min-width: 850px) and (max-width: 949px) {
  .banner-header {
    width: fit-content !important;
    position: absolute !important;
    left: 55% !important;
    top: 22% !important;
    align-items: flex-end !important;
  }
  .banner-heading1 {
    font-size: 30px !important;
    text-align: center !important;
    padding-left: 10% !important;
  }
  .banner-heading2 {
    font-size: 30px !important;
    width: 73% !important;
    text-align: center !important;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .banner-wrapper {
    position: relative !important;
  }
  .banner-header {
    top: 26% !important;
    left: 42% !important;
    width: fit-content !important;
    position: absolute !important;
  }
  .banner-heading1 {
    font-size: 30px !important;
    width: 100% !important;
    text-align: center !important;
    padding-left: 10% !important;
    position: absolute;
    bottom: 100% !important;
    left: 8.4% !important;
  }
  .banner-heading2 {
    font-size: 30px !important;
    width: 73% !important;
    text-align: center !important;
    /* position: absolute !important; */
    padding-right: 0% !important;
    padding-left: 1rem !important;
    /* left: 7% !important; */
  }
  .subscription-wrapper {
    top: 34% !important;
  }
  .by-subscription {
    font-size: medium !important;
    line-height: 1.5rem !important;
  }
  .banner-title {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .banner-wrapper {
    position: relative !important;
  }
  .banner-header {
    top: 26% !important;
    left: 46% !important;
    width: fit-content !important;
    position: absolute !important;
  }
  .banner-heading1 {
    font-size: 38px !important;
    width: 108.3% !important;
    text-align: end !important;
    padding-left: 10% !important;
    position: absolute !important;
    bottom: 100% !important;
    left: 8.4% !important;
  }
  .banner-heading2 {
    font-size: 38px !important;
    text-align: end !important;
    width: 93.6% !important;
    padding-right: 8% !important;
  }
  .subscription-wrapper {
    top: 36% !important;
  }
  .by-subscription {
    font-size: large !important;
    line-height: 1.5rem !important;
  }
  .banner-title {
    font-size: larger !important;
    line-height: 1.5rem !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: portrait) {
  .banner-wrapper {
    position: relative !important;
  }
  .banner-header {
    top: 26% !important;
    left: 47% !important;
    width: fit-content !important;
    position: absolute !important;
  }
  .banner-heading1 {
    font-size: 38px !important;
    width: 112% !important;
    text-align: center !important;
    padding-left: 10% !important;
    position: absolute;
    bottom: 100% !important;
    left: -1.6% !important;
  }
  .banner-heading2 {
    font-size: 38px !important;
    width: 83.5% !important;
    text-align: center !important;
    /* position: absolute !important; */
    padding-right: 0% !important;
    padding-left: 2rem !important;
    /* left: 7% !important; */
  }
  .subscription-wrapper {
    top: 37% !important;
  }
  .by-subscription {
    font-size: large !important;
    line-height: 1.75rem !important;
  }
  .banner-title {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}
@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .subscription-wrapper {
    top: 42% !important;
  }
  .by-subscription {
    font-size: x-large !important;
    line-height: 1.5rem !important;
  }
  .banner-title {
    font-size: x-large !important;
    line-height: 1.5rem !important;
  }
}
