/* html, body {
  overflow-x: hidden;
}
body {
  position: relative
} */

/* .mobile-banner-wrapper{
  overflow-x: hidden;
} */
.mobile-banner-wrapper {
  top: 56px;
  height: calc(100vh - 300px);
}

.font-synthese {
  font-family: "synthese";
}

@media only screen and (min-width: 640px) and (max-width: 700px) {
  .signin-logo {
    width: 6rem !important;
    /* height: 2rem !important; */
    margin-left: 1rem !important;
  }

  .darktheme-btn-day,
  .darktheme-btn-night {
    width: 3xrem !important;
  }
}

body {
  font-family: synthese, sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: campaign, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.07em;
}

.description2 {
    color: rgb(227, 1, 133);
}

/* @media screen and (min-width: 218px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

/* .block_landscape {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background: white;
  width: 100%;
  height: 100%;
  display: none;
} */
/* .circle {
  position: fixed;
  display: inline-block;
  border-radius: 50%;
  /* border: 5px solid rgb(31,31,31); */
/* background-color: #E30185;
  width: 30px;
  height: 30px;
  left: 20px;
  right: 0;
  transition: transform .2s ease, opacity .2s ease, border-color .2s ease, background-color .2s ease;
  pointer-events: none;
  z-index: 999;
  font-size: 6px;
  padding: 5px 0px;
  text-align: center;
  color: #000; */
/* text-shadow: 0px 2px 4px rgb(114 237 202); */
/* box-shadow: 0px 0px 0px rgba(118, 92,255, 0.8); */
/* }  */

.main-content {
  text-align: left;
  width: 100%;
  padding: 3% 10%;
}

.main-content h3 {
  padding: 10px 10px 10px 0px;
}

.main-content p {
  padding: 5px 5px 5px 0px;
}

.top-content-design {
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
  margin-top: 10px;
}

.text-dark {
  color: #000000;
}

.d-help {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.d-help-chooseplan {
  align-items: flex-start;
}

.width-20 {
  width: 20%;
}

.width-24 {
  width: 24%;
}

.content-text {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  margin: 1rem 0;
  color: rgb(84, 84, 84);
}

.visiblity-hidden {
  visibility: hidden;
}

.width-60 {
  width: 60%;
}

.width-45 {
  width: 45%;
}

.width-15 {
  width: 15%;
}

.width-12 {
  width: 12%;
}

.width-20 {
  width: 20%;
}

.footer-container-spacing {
  margin-top: 3rem;
}

.common-content-text {
  color: #000000;
  font-weight: 300;
  font-size: 16px;
}

.secondary-title {
  font-size: 14px;
  font-weight: 800;
  margin-top: 10px;
  padding: 0px 15px;
  text-align: center;
}

.img-content {
  display: flex;
  width: 100%;
}

.set-img-height {
  height: 50px;
  width: 50px;
}

.set-img-height img {
  height: 100% !important;
  width: 100% !important;
}

.text-font-mobile {
  display: none;
}

.mobile-hide-content {
  display: block;
}

.font-semibold {
  font-weight: 600;
}

.aline-chooseplans {
  align-items: flex-start;
}

.solo-bulletpoints-wrapper {
}

/* tablet css */
@media screen and (max-width: 960px) {
  .text-xl {
    font-size: 1rem;
  }

  .plan-caraousal-wrapper {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .sm\:text-4xl {
    font-size: 1rem;
  }

  .tablet-width-button {
    width: 7rem;
  }

  .tablet-w-20 {
    width: 18rem;
  }

  .tab-width {
    width: 45%;
    margin-right: 20px;
  }

  .tablet-content-scroller {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .aboutFounder {
    width: auto;
  }

  .nav-menu {
    margin-right: 20px;
  }

  .smlogo {
    height: 4rem;
    margin-top: auto;
  }

  .tablet-w-20 {
    width: 15rem;
  }

  .card-items {
  }

  .nav-items {
    margin-left: 1rem;
  }

  .nav-items {
    margin-left: 0.7rem;
  }

  /* .ai-img-width {
    width: 55px;
  } */
}

/* mobile css */
@media screen and (max-width: 480px) {
  .sm-width-100 {
    width: 100% !important;
  }

  .set-img-height {
    height: 40px;
    width: 40px;
  }

  .text-xl {
    font-size: 1rem;
  }

  .subtitels-hight {
    line-height: 1.5;
  }

  .text-data {
    font-size: 0.9rem;
  }

  .sm-feature-glance {
    margin-top: 2rem;
  }

  .mobile-responsive-img {
    margin: 0px;
    width: 23px;
  }

  .sm-justify-start {
    justify-content: start;
  }

  .sm-content-hidden {
    display: none;
  }

  .common-content-text {
    font-size: 12px;
  }

  .footer-container-spacing {
    margin: 0px 25px 30px;
  }

  .footer-links {
    width: 100%;
  }

  .inspiration-content {
    display: none;
  }
  .footer-main-content-mobile {
    margin-top: 0px;
  }

  .sm-sync-logo {
    width: auto;
  }

  .sm-sync-logo div:nth-child(2) {
    padding: 0px;
    align-items: center;
    width: 100%;
  }

  .inspiration-carosal-mobile {
    padding: 0px;
  }

  .tablet-w-20 {
    width: 100%;
  }

  .text-font-mobile {
    display: block;
  }

  .mobile-hide-content {
    display: none;
  }

  .alinetext {
    margin-left: 0%;
  }


}

@media only screen and (device-width: 540px) and (device-height: 720px) {
  .text-font-mobile {
    display: block;
  }

  .mobile-hide-content {
    display: none;
  }

  .card-items {
    margin-left: .5rem;
    margin-top: .10rem;
  }


}

@media all and (device-width: 1024px) and (device-height: 600px) and (orientation: landscape) {


  .tablet-content-scroller {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .plan-caraousal-wrapper {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

@media only screen and (min-width: 480px) and (max-width :640px) {
  .inspiration-content {
    display: none;
  }

  .sm-content-hidden {
    display: none;
  }

  .sm-width-100 {
    width: 100% !important;
  }

  .sm-justify-start {
    justify-content: start;
  }

  .text-font-mobile {
    display: block;
  }

  .mobile-hide-content {
    display: none;
  }

  .alinetext {
    margin-left: auto;
  }

  .tablet-w-20 {
    width: 100%;
  }

  .sm-sync-logo div:nth-child(2) {
    padding: 0px;
    align-items: center;
    width: 100%;
  }

  .footer-text {
    padding-top: 1rem;
  }

  #sound-design-container {
    padding-right: 4% !important;
    padding-left: 4% !important;
  }


}

@media only screen and (device-width: 640px) {
  .text-font-mobile {
    display: none;
  }

  .mobile-hide-content {
    display: block;
  }

  .alinetext {
    margin-left: 10%;
  }

  .sm-width-20 {
    width: 20% !important;
  }

  .sm-justify-start {
    justify-content: start;
  }

  .sm-content-hidden {
    display: block;
  }

  .tablet-content-scroller {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .inspiration-content {
    display: inline-flex;
  }

  .sm-content-hidden {
    display: -webkit-inline-box;
  }

  .footer-text {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #sound-design-container {
    padding-right: 4% !important;
    padding-left: 4% !important;
  }

  #sound-design-container {
    padding-right: 4% !important;
    padding-left: 4% !important;
  }

  .ai-img-width {
    width: 50px !important;
  }
.plancolumn {
  min-width:220px;
}

.planticket {
  height:520px;
}
}

@media only screen and (max-width: 640px) {
  .plan-caraousal-wrapper {
    display:none;
  }
  .plancolumn{
    min-width: 98vw;
    max-width: 98vw;
    width: 98vw;
    margin-right: 1vw;
  }
  #plan-caraousal-container {
  }
  .inspiration-carosal-mobile {
    display: none;
}
  .width-20 {
    width:90vw;
  }
  .planticket {
    min-height: 400px;
    padding: 5vw 10vw;
  }
}

@media only screen and (min-width: 640px) {
  .plan-caraousal-wrapper {
  }
  #plan-caraousal-container {
    display:none;
  }
  .planticket {
    min-height: 800px;
  }
}

@media only screen and (min-width: 800px) {
  .price-container-monthly {
    font-size: 1rem;
  }
  .planticket {
    min-height: 700px;
  }
}

.text-black {
  color: "#000000"
}