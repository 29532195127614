.font-campaign-sound-plan {
  font-family: "campaign" !important;
}

.font-synthese-sound-plan {
  font-family: "synthese" !important;
  /* font-weight: 400 !important; */
}

@media only screen and (max-width: 280px) {
  .tile-content {
    width: 7rem;
    height: 7rem;
  }

  .tile-desc {
    width: 7rem;
  }

  .ai-img-width {
    width: 53px;
  }
}

@media only screen and (min-width: 281px) and (max-width: 320px) {
  .tile-content {
    width: 8rem !important;
    height: 8rem !important;
  }

  .tile-desc {
    width: 8rem !important;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .tile-content {
    width: 9.5rem !important;
    height: 9.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .tile-desc {
    width: 9.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
  .tile-content {
    width: 10rem !important;
    height: 10rem !important;
  }

  .tile-desc {
    width: 10rem !important;
  }
}

@media only screen and (device-width: 540px) and (device-height: 720px) {
  .tile-content {
    width: 14rem !important;
    height: 14rem !important;
  }

  .tile-desc {
    width: 14rem !important;
  }

  .play-media {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }

  .share-btn {
    width: 2.75rem !important;
    height: 2.25rem !important;
  }

  .sm-width-100 {
    width: 100% !important;
  }

  .sm-justify-start {
    justify-content: start;
  }

  .tablet-content-scroller {
    display: none;
  }

  .inspiration-content {
    display: none;
  }

  .description2 {
    left: 25.5% !important;
  }
}

@media only screen and (min-width: 640px) and (max-width: 800px) {
  .sound-design-container {
    padding-right: 4% !important;
    padding-left: 4% !important;
  }

  .sound-title1,
  .sound-title2 {
    font-size: large !important;
  }

  .sound-details1,
  .sound-details2 {
    font-size: medium !important;
  }

  .tile-content {
    height: 8rem !important;
    width: 8rem !important;
  }

  .tile-desc {
    width: 8rem !important;
    /* font-size: 1rem !important; */
    /* line-height: 1.5rem !important; */
  }

  .footer-container {
    padding-bottom: 1rem !important;
  }

  .copyright-wrapper {
    padding-right: 4% !important;
    padding-left: 4% !important;
  }

  .copyright-main {
    padding-top: 1rem !important;
  }

  .copyright-details {
    padding-left: 1rem !important;
  }

  .sound-design-main-container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .ai-img-width {
    width: 55px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .sound-design-container {
    padding-right: 12% !important;
    padding-left: 12% !important;
  }

  .tile-content {
    height: 9.5rem !important;
    width: 9.5rem !important;
    padding-right: 0.5rem !important;
  }

  .tile-desc {
    width: 9.5rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .footer-container {
    padding-bottom: 1rem !important;
  }

  .copyright-wrapper {
    padding-right: 12% !important;
    padding-left: 12% !important;
  }

  .copyright-main {
    padding-top: 1.25rem !important;
  }

}

@media only screen and (min-width: 1001px) and (max-width: 1300px) {

  /* .sound-design-container {
    padding-right: 14% !important;
    padding-left: 14% !important;
  } */
  .tile-content {
    height: 11rem !important;
    width: 11rem !important;
    padding-right: 0.5rem !important;
  }

  .tile-desc {
    width: 11rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .footer-container {
    padding-bottom: 1rem !important;
  }

  .copyright-wrapper {
    padding-right: 14% !important;
    padding-left: 14% !important;
  }

  .copyright-main {
    padding-top: 1.25rem !important;
  }
}

/* @media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .sound-design-container {
    padding-right: 14% !important;
    padding-left: 14% !important;
  }
  .tile-content {
    height: 11rem !important;
    width: 11rem !important;
    padding-right: 0.5rem !important;
  }
  .tile-desc {
    width: 11rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
} */

@media only screen and (min-width: 280px) and (max-width: 414px) {
  .link-heading {
    font-size: x-small !important;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .sound-design-container {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sound-header {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }

  .sound-description {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .sound-title1,
  .sound-title2,
  .sound-title3 {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }

  .sound-details1,
  .sound-details2,
  .sound-details3 {
    font-size: 0.85rem !important;
    line-height: 1.35rem !important;
  }

  .genral-description1,
  .genral-description2 {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  .tile-content {
    height: 9rem !important;
    width: 9rem !important;
  }

  .tile-desc {
    width: 9rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .play-media,
  .share-btn {
    opacity: 100 !important;
  }

  .play-media {
    width: 2rem !important;
    height: 2rem !important;
  }

  .share-btn {
    width: 2.5rem !important;
    height: 2rem !important;
  }

  .footer-links {
    justify-content: space-around !important;
  }

  .links1,
  .links2 {
    width: 50% !important;
    padding-left: 3rem !important;
  }

  .copyright-wrapper {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .copyright-main {
    padding-top: 1rem !important;
  }

  .copyright-details {
    padding-left: 2.5rem !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .sound-design-container {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sound-header {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }

  .sound-description {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .sound-title1,
  .sound-title2,
  .sound-title3 {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }

  .sound-details1,
  .sound-details2,
  .sound-details3 {
    font-size: 0.85rem !important;
    line-height: 1.35rem !important;
  }

  .genral-description1,
  .genral-description2 {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .tile-content {
    height: 11rem !important;
    width: 11rem !important;
  }

  .tile-desc {
    width: 11rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .play-media,
  .share-btn {
    opacity: 100 !important;
  }

  .play-media {
    width: 2rem !important;
    height: 2rem !important;
  }

  .share-btn {
    width: 2.5rem !important;
    height: 2rem !important;
  }

  .footer-links {
    justify-content: space-around !important;
  }

  .links1,
  .links2 {
    width: 50% !important;
    padding-left: 3rem !important;
  }

  .copyright-wrapper {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .copyright-main {
    padding-top: 1rem !important;
  }

  .copyright-details {
    padding-left: 2.5rem !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: portrait) {
  .sound-design-container {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .sound-header {
    font-size: 2rem !important;
    line-height: 2.25rem !important;
  }

  .sound-description {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .sound-title1,
  .sound-title2,
  .sound-title3 {
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
  }

  .sound-details1,
  .sound-details2,
  .sound-details3 {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }

  .genral-description1,
  .genral-description2 {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }

  .tile-content {
    height: 11rem !important;
    width: 11rem !important;
  }

  .tile-desc {
    width: 11rem !important;
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }

  .play-media,
  .share-btn {
    opacity: 100 !important;
  }

  .play-media {
    width: 2rem !important;
    height: 2rem !important;
  }

  .share-btn {
    width: 2.5rem !important;
    height: 2rem !important;
  }

  .footer-links {
    justify-content: space-around !important;
  }

  .links1,
  .links2 {
    width: 50% !important;
  }

  .copyright-wrapper {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .copyright-main {
    padding-top: 1rem !important;
  }

  .copyright-details {
    padding-left: 1.5rem !important;
  }
}

@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {

  .play-media,
  .share-btn {
    opacity: 100 !important;
  }
}

.features>p {
  flex: 50%;
}

@media all and (min-width: 640px) {
  .features>p:nth-child(odd) {
    padding-right: 20px;
  }

  .features>p:nth-child(even) {
    padding-left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .sound-design-main-container {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}

/* Changes */
h1.sound-header.font-campaign-sound-plan.font-medium.text-2xl.sm\:text-4xl {
  padding-top: 10%;
}

@media all and (device-width: 820px) and (device-height: 1180px) {
  .ai-img-width {
    width: 55px
  }
}

.featurebox {
  width: 45%;
  padding-top: 2%;
}

@media screen and (max-width: 768px) {
  .featurebox {
    width: 90%;
    padding-top: 2%;
  }
}


