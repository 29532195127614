.font-campaign-corporate-plan {
  font-family: "campaign" !important;
}

.font-synthese-corporate-plan {
  font-family: "synthese" !important;
  /* font-weight: 400 !important; */
}

.corporate-plan-top {
  position: relative;
  border-bottom-left-radius: 44px;
  border-bottom-right-radius: 44px;
}

.corporate-bullet-points-wrapper {
  background-image: url("../../assets/card-border-layout-top.png") !important;
  background-repeat: no-repeat;
  background-size: 88%;
  background-position: 40% 0%;
}

.corporate-plan-top::before {
  content: "";
  position: absolute;
  top: -25px;
  left: -25px;
  width: 50px;
  height: 50px;
  /* background-color: #fff; */
  background-color: #f1f1f1;
  border-radius: 50%;
}

html.dark .corporate-plan-top::before {
  background-color: #000;
}

html.dark .corporate-plan-top::after {
  background-color: #000;
}

.corporate-plan-top::after {
  content: "";
  position: absolute;
  top: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  /* background-color: #fff; */
  background-color: #f1f1f1;
  border-radius: 50%;
}

.corporate-plan-bottom {
  position: relative;
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  top: -3%;
}

.corporate-plan-price-wrapper {
  background-image: url("../../assets/card-border-layout-bottom.png");
  background-repeat: no-repeat;
  background-size: 88%;
  background-position: 45% 45%;
}

.corporate-plan-bottom::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: -25px;
  width: 50px;
  height: 50px;
  /* background-color: #fff; */
  background-color: #f1f1f1;
  border-radius: 50%;
}

.corporate-plan-bottom::after {
  content: "";
  position: absolute;
  bottom: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  /* background-color: #fff; */
  background-color: #f1f1f1;
  border-radius: 50%;
}

html.dark .corporate-plan-bottom::after {
  background-color: #000;
}

html.dark .corporate-plan-bottom::before {
  background-color: #000;
}

.rounded-divider-corporate {
  padding: 10px;
  background-color: #a694c5;
  margin-left: -17px;
  margin-right: 49px;
  position: relative;
  top: -1.5%;
  left: 6%;
  border-radius: 100%;
}

.rounded-divider-corporate::before {
  content: "";
  width: 8%;
  height: 80%;
  top: 8%;
  left: -2%;
  /* background-color: #fff; */
  background-color: #f1f1f1;
  position: absolute;
  border-radius: 100%;
}

.rounded-divider-corporate::after {
  content: "";
  width: 8.8%;
  height: 80%;
  top: 8%;
  right: -2%;
  /* background-color: #fff; */
  background-color: #f1f1f1;
  position: absolute;
  border-radius: 100%;
}

html.dark .rounded-divider-corporate::after {
  background-color: #000;
}

html.dark .rounded-divider-corporate::before {
  background-color: #000;
}

@media (max-width: 280px) {
  .corporate-bullet-points-wrapper {
    background-image: url("../../assets/corporate-border-layout-top.png") !important;
    background-size: 90% !important ;
    padding-top: 0.23rem !important;
  }
  .corporate-plan-price-wrapper {
    background-size: 88% !important;
  }
  .corporate-plan-top {
    height: 370px !important;
  }
  .corporate-plan-bottom {
    padding-top: 1.25rem !important;
  }

  .rounded-divider-corporate {
    margin-right: 25px !important;
  }
  .corporate-bullet-points-wrapper {
    margin-top: 8px;
  }
  .corporate-heading {
    font-size: medium !important;
  }
  .corporate-description {
    font-size: small !important;
    line-height: 12px;
  }
  .point-1,
  .point-2,
  .point-3,
  .point-4,
  .point-5,
  .point-6,
  .point-7 {
    margin-left: 25px !important;
  }
  .corporate-point-desc {
    font-size: 0.6rem !important;
    line-height: 0.7rem !important;
  }
  .corporate-point-img-desc {
    width: 0.65rem !important;
  }
  .price-contact {
    padding-top: 12px;
    font-size: small !important;
  }
  .corporate-plan-link {
    padding-top: 28px !important;
  }
  .corporate-plan-btn {
    height: 1rem !important;
    width: 6rem !important;
    font-size: x-small !important;
  }
  .rounded-divider-corporate::before {
    width: 21% !important;
    height: 100% !important;
    top: 0% !important;
    left: -2% !important;
  }

  .rounded-divider-corporate::after {
    width: 13% !important;
    height: 80% !important;
    top: 10% !important;
    right: -4% !important;
  }
  .corporate-bullet-points-wrapper {
    padding-bottom: 2.25rem !important;
  }
  .rounded-divider-corporate-border {
    width: 66% !important;
    left: 22% !important;
  }
  .corporate-plan-price-wrapper {
    padding-bottom: 1.5rem;
  }
}

@media screen and (min-width: 281px) and (max-width: 320px) {
  .corporate-bullet-points-wrapper {
    background-image: url("../../assets/corporate-border-layout-top.png") !important;
    background-size: 85% !important ;
  }
  .corporate-plan-price-wrapper {
    background-size: contain !important;
  }
  .corporate-plan-top {
    height: 410px !important;
  }
  .rounded-divider-corporate {
    margin-right: 25px !important;
  }
  .corporate-bullet-points-wrapper {
    margin-top: 0.5rem !important;
  }
  .corporate-heading {
    font-size: medium !important;
  }
  .corporate-description {
    font-size: small !important;
    line-height: 12px !important;
  }
  .point-1,
  .point-2,
  .point-3,
  .point-4,
  .point-5,
  .point-6,
  .point-7 {
    margin-left: 30px !important;
  }
  .bullet-points {
    padding-top: 0.2rem !important;
  }
  .corporate-point-desc {
    font-size: 0.75rem !important;
  }
  .corporate-point-img-desc {
    width: 0.75rem !important;
  }
  .price-contact {
    font-size: small !important;
    padding-top: 1.25rem;
  }
  .corporate-plan-link {
    padding-top: 1.5rem !important;
  }
  .corporate-plan-btn {
    height: 1.5rem !important;
    width: 10rem !important;
    font-size: small !important;
  }
  .rounded-divider-corporate::before {
    width: 14% !important;
    height: 89% !important;
    top: 6% !important;
    left: 0% !important;
  }

  .rounded-divider-corporate::after {
    width: 8% !important;
    height: 70% !important;
    top: 20% !important;
    right: 0% !important;
  }
  .corporate-bullet-points-wrapper {
    padding-bottom: 2.25rem !important;
  }
  .rounded-divider-corporate-border {
    width: 70% !important;
    left: 18% !important;
  }
  .corporate-plan-price-wrapper {
    padding-bottom: 1.5rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 360px) {
  .corporate-bullet-points-wrapper {
    /* padding-bottom: 2rem !important; */
    background-size: 91% !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 413px) {
  .corporate-plan-top {
  }
  .rounded-divider-corporate {
    margin-right: 25px !important;
  }
  .rounded-divider-corporate::before {
    height: 84% !important;
    top: 10% !important;
    left: -2% !important;
    width: 10% !important;
  }
  .corporate-bullet-points-wrapper {
    background-image: url("../../assets/corporate-border-layout-top.png") !important;
    background-size: 78% !important;
    padding-bottom: 8rem !important;
  }
  .corporate-plan-price-wrapper {
    background-size: contain !important;
  }
  .rounded-divider-corporate-border {
    width: 75% !important;
    left: 14% !important;
  }
  .corporate-point-desc {
    padding-right: 1rem !important;
    font-size: 0.75rem !important;
    line-height: 1rem;
  }
}

@media screen and (min-width: 414px) and (max-width: 640px) {
  .corporate-plan-top {
  }
  .rounded-divider-corporate {
    margin-right: 25px !important;
  }
  .rounded-divider-corporate::before {
    height: 84% !important;
    top: 10% !important;
    left: 0% !important;
    width: 8% !important;
  }
  .corporate-bullet-points-wrapper {
    /* background-image: url('../../assets/corporate-border-layout-top.png') !important; */
    background-size: 78% !important ;
    padding-bottom: 8rem !important;
    padding-right: 1rem !important;
  }
  .corporate-plan-price-wrapper {
    background-size: contain !important;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1379px) {
  .rounded-divider-corporate {
    top: -1% !important;
    left: 11% !important;
  }
  .rounded-divider-corporate::before {
    height: 84% !important;
    top: 27% !important;
    left: -1% !important;
    width: 8% !important;
  }
  /* .rounded-divider-corporate::after {
    height: 84% !important;
    top: 3% !important;
    left: -1% !important;
    width: 8% !important;
  } */
}

@media only screen and (min-width: 1380px) and (max-width: 1400px) {
  .rounded-divider-corporate {
    top: -1% !important;
    left: 11% !important;
  }
  .rounded-divider-corporate::before {
    /* height: 84% !important;
    top: 27% !important;
    left: -1% !important; */
    width: 5% !important;
  }
  .rounded-divider-corporate::after {
    /* height: 84% !important;
    top: 3% !important;
    left: -1% !important; */
    width: 12% !important;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .rounded-divider-corporate::before {
    width: 20% !important;
    height: 131% !important;
    top: 13% !important;
    left: -10% !important;
  }
  .rounded-divider-corporate::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }
  .rounded-divider-corporate-border {
    width: 77% !important;
    left: 16% !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .rounded-divider-corporate::before {
    width: 20% !important;
    height: 131% !important;
    top: 13% !important;
    left: -10% !important;
  }
  .rounded-divider-corporate::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }
  .rounded-divider-corporate-border {
    width: 77% !important;
    left: 16% !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: portrait) {
  .rounded-divider-corporate::before {
    width: 13% !important;
    height: 90% !important;
    top: 26% !important;
    left: -2% !important;
  }
  .rounded-divider-corporate::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }
  .rounded-divider-corporate-border {
    width: 77% !important;
    left: 16% !important;
  }
}
@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .rounded-divider-corporate::before {
    width: 13% !important;
    height: 90% !important;
    top: 26% !important;
    left: -2% !important;
  }
  .rounded-divider-corporate::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }
  .rounded-divider-corporate-border {
    width: 77% !important;
    left: 16% !important;
  }
}
