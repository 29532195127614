.font-campaign-solo-plan {
  font-family: "campaign" !important;
}

.font-synthese-solo-plan {
  font-family: "synthese" !important;
  /* font-weight: 400 !important; */
}

.solo-plan-top {
  position: relative;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.solo-bulletpoints-wrapper {
  /* background-image: url("../../assets/card-border-layout-top.png") !important; */
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: 40% 0%;
}

.solo-plan-top::before {
  content: "";
  position: absolute;
  top: -25px;
  left: -25px;
  width: 50px;
  height: 50px;
  /* Changes */
  background-color: #fff;
  /* background-color: #f1f1f1; */
  border-radius: 50%;
}

html.dark .solo-plan-top::before {
  background-color: #000;
}

html.dark .solo-plan-top::after {
  background-color: #000;
}

.solo-plan-top::after {
  content: "";
  position: absolute;
  top: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  /* background-color: #f1f1f1; */
  border-radius: 50%;
}

.solo-plan-bottom {
  position: relative;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  top: -3%;
}

.solo-plan-price-wrapper {
  background-image: url("../../assets/card-border-layout-bottom.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 45% 0%;
}

.solo-plan-bottom::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: -25px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  /* background-color: #f1f1f1; */
  border-radius: 50%;
}

.solo-plan-bottom::after {
  content: "";
  position: absolute;
  bottom: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  /* background-color: #f1f1f1; */
  border-radius: 50%;
}

html.dark .solo-plan-bottom::after {
  background-color: #000;
}

html.dark .solo-plan-bottom::before {
  background-color: #000;
}

.rounded-divider-solo {
  padding: 10px;
  background-color: #f9c5cb;
  margin-left: -17px;
  margin-right: 49px;
  position: relative;
  top: -1.5%;
  left: 6%;
  border-radius: 100%;
}

.rounded-divider-solo::before {
  content: "";
  width: 8%;
  height: 80%;
  top: 8%;
  left: -2%;
  background-color: #fff;
  /* background-color: #f1f1f1; */
  position: absolute;
  border-radius: 100%;
}

.rounded-divider-solo::after {
  content: "";
  width: 8.8%;
  height: 80%;
  top: 8%;
  right: -2%;
  background-color: #fff;
  /* background-color: #f1f1f1; */
  position: absolute;
  border-radius: 100%;
}

html.dark .rounded-divider-solo::after {
  background-color: #000;
}

html.dark .rounded-divider-solo::before {
  background-color: #000;
}

.card-items {
  margin-top: .25rem;

}

@media screen and (max-width: 280px) {
  .solo-bulletpoints-wrapper {
  }

  .solo-plan-top {
    height: 370px !important;
  }

  .solo-plan-bottom {
    padding-top: 1.25rem !important;
  }

  .rounded-divider-solo {
    margin-right: 20px !important;
  }

  .solo-bulletpoints-wrapper {
    margin-top: 10px !important;
  }

  .solo-heading {
    font-size: medium !important;
  }

  .solo-description {
    font-size: smaller !important;
    line-height: 12px;
  }

  .point-1,
  .point-2,
  .point-3,
  .point-4,
  .point-5,
  .point-6,
  .point-7 {
    margin-left: 20px !important;
  }

  .solo-bullet-points {
    padding-top: 0.6rem !important;
  }

  .solo-point-desc {
    font-size: 0.6rem !important;
    line-height: 0.7rem !important;
  }

  .solo-point-img-desc {
    width: 0.65rem !important;
  }

  .price-container {
    padding-top: 0.4rem !important;
  }

  .price-container-monthly {
    padding-top: 10px !important;
    font-size: smaller !important;
  }

  .price-container-yearly {
    font-size: xx-small !important;
  }

  .solo-plan-link {
    padding-top: 12px !important;
  }

  .solo-plan-btn {
    height: 1rem !important;
    width: 6rem !important;
    font-size: x-small !important;
  }

  .rounded-divider-solo::before {
    width: 21% !important;
    height: 100% !important;
    top: 0% !important;
    left: -2% !important;
  }

  .rounded-divider-solo::after {
    width: 13% !important;
    height: 80% !important;
    top: 10% !important;
    right: -2% !important;
  }

  .solo-bulletpoints-wrapper {
  }

  .rounded-divider-solo-border {
    width: 63% !important;
    left: 24% !important;
  }

  .solo-plan-price-wrapper {
    padding-bottom: 1.25rem !important;
    /* padding-top: 1rem; */
    background-size: 93% !important;
  }
}

@media screen and (min-width: 281px) and (max-width: 320px) {
  .solo-bulletpoints-wrapper {
  }

  .solo-plan-top {
    height: 410px !important;
  }

  .solo-bullet-points {
    padding-top: 0.45rem !important;
  }

  .rounded-divider-solo {
    margin-right: 25px !important;
  }

  .solo-bulletpoints-wrapper {
    margin-top: 18px !important;
  }

  .solo-description {
    font-size: small !important;
    line-height: 12px !important;
  }

  .point-1,
  .point-2,
  .point-3,
  .point-4,
  .point-5,
  .point-6,
  .point-7 {
    margin-left: 30px !important;
  }

  .solo-point-desc {
    font-size: 0.75rem !important;
  }

  .solo-point-img-desc {
    width: 0.75rem !important;
  }

  .solo-heading {
    font-size: medium !important;
  }

  .price-container {
    padding-top: 0.25rem !important;
  }

  .price-container-monthly {
    padding-top: 12px !important;
    font-size: smaller !important;
  }

  .price-container-yearly {
    font-size: small !important;
  }

  .solo-plan-link {
    padding-top: 1.5rem !important;
  }

  .solo-plan-btn {
    height: 1.5rem !important;
    width: 10rem !important;
    font-size: small !important;
  }

  .rounded-divider-solo::before {
    width: 15% !important;
    height: 89% !important;
    top: 6% !important;
    left: -1% !important;
  }

  .rounded-divider-solo::after {
    width: 9% !important;
    height: 70% !important;
    top: 20% !important;
    right: -1% !important;
  }

  .solo-bulletpoints-wrapper {
  }

  .rounded-divider-solo-border {
    width: 70% !important;
    left: 18% !important;
  }

  .solo-plan-price-wrapper {
    padding-bottom: 0.5rem;
  }
}

@media screen and (min-width: 321px) and (max-width: 360px) {
  .solo-bulletpoints-wrapper {
    /* padding-bottom: 5rem !important; */
    background-size: 90% !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 413px) {
  .solo-plan-top {
  }

  .rounded-divider-solo {
    margin-right: 25px !important;
  }

  .rounded-divider-solo::before {
    height: 84% !important;
    top: 10% !important;
    left: -2% !important;
    width: 10% !important;
  }

  .rounded-divider-solo-border {
    width: 75% !important;
    left: 14% !important;
  }

  .solo-bulletpoints-wrapper {
    background-size: 78% !important;
  }

  .solo-description {
    font-size: smaller !important;
  }

  .solo-point-desc {
    font-size: 0.75rem !important;
    padding-right: 1.25rem !important;
  }

  .solo-point-img-desc {
    width: 0.75rem !important;
  }
}

@media screen and (min-width: 414px) and (max-width: 639px) {
  .solo-plan-top {
  }

  .rounded-divider-solo {
    margin-right: 25px !important;
  }

  .rounded-divider-solo::before {
    height: 84% !important;
    top: 10% !important;
    left: 0% !important;
    width: 8% !important;
  }

  .solo-bulletpoints-wrapper {
  }

  .solo-bullet-points {
    padding-right: 1rem;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1379px) {
  .rounded-divider-solo {
    top: -1% !important;
    left: 11% !important;
  }

  .rounded-divider-solo::before {
    height: 84% !important;
    top: 27% !important;
    left: -1% !important;
    width: 8% !important;
  }

  /* .rounded-divider-solo::after {
    height: 84% !important;
    top: 3% !important;
    left: -1% !important;
    width: 8% !important;
  } */
}

@media only screen and (min-width: 1380px) and (max-width: 1400px) {
  .rounded-divider-solo {
    top: -1% !important;
    left: 11% !important;
  }

  .rounded-divider-solo::before {
    /* height: 84% !important;
    top: 27% !important;
    left: -1% !important; */
    width: 5% !important;
  }

  .rounded-divider-solo::after {
    /* height: 84% !important;
    top: 3% !important;
    left: -1% !important; */
    width: 12% !important;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .rounded-divider-solo::before {
    width: 27% !important;
    height: 131% !important;
    top: 13% !important;
    left: -16% !important;
  }

  .rounded-divider-solo::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }

  .rounded-divider-solo-border {
    width: 77% !important;
    left: 16% !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .rounded-divider-solo::before {
    width: 27% !important;
    height: 131% !important;
    top: 13% !important;
    left: -16% !important;
  }

  .rounded-divider-solo::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }

  .rounded-divider-solo-border {
    width: 77% !important;
    left: 16% !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: portrait) {
  .rounded-divider-solo::before {
    width: 13% !important;
    height: 90% !important;
    top: 26% !important;
    left: -2% !important;
  }

  .rounded-divider-solo::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }

  .rounded-divider-solo-border {
    width: 77% !important;
    left: 16% !important;
  }
}

@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .rounded-divider-solo::before {
    width: 13% !important;
    height: 90% !important;
    top: 26% !important;
    left: -2% !important;
  }

  .rounded-divider-solo::after {
    width: 0% !important;
    height: 80% !important;
    top: 8% !important;
    right: -2% !important;
  }

  .rounded-divider-solo-border {
    width: 77% !important;
    left: 16% !important;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .card-items {
  }

}