.yellowbg {
  background-color: #faec64;
}

.about-description {
  color: #e30185;
  padding-bottom: 10px;
  font-family: campaign, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 32px;
}

.about-content {
  padding-bottom: 20px;
  font-family: synthese, sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.7;
}

.pinkhighlight {
  color: #ffffff;
  background-color: #e30185;
  font-family: synthese, sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 2px;
}

.pink {
  color: #e30185;
  /* font-size: 24px; */
  font-weight: bold;
}

.black {
  color: #000000;
  /* font-size: 22px; */
}

.aboutFounder {
  border-radius: 50%;
  border: 10px solid #f6fd33;
  position: absolute;
  margin-top: 15%;
  margin-left: 70%;
}

.bannerImage {
  position: relative;
}
