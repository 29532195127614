/* @media only screen and (width: 768px) {
  .nav-tabs{
    width: 50%;
  }
} */

.font-synthese400 {
  font-family: "synthese" !important;
  font-weight: 400 !important;
}

@media only screen and (min-width: 640px) and (max-width: 700px) {

  .nav-night-logo,
  .nav-day-logo {
    width: 6rem !important;
    /* height: 2rem !important; */
    margin-left: 1rem !important;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {

  .music-tab,
  .sound-tab,
  .inspiration-tab,
  .pricing-tab {
    margin-left: 0rem;
  }

  .nav-night-logo,
  .nav-day-logo {
    width: 6rem;
    height: 4rem;
    margin-left: 1rem;
  }

  .music-tab-link,
  .sound-tab-link,
  .inspiration-tab-link,
  .pricing-tab-link {
    font-size: medium;
  }

  .nav-tabs {
    width: 100%;
    justify-content: space-around;
  }


}

@media screen and (max-width: 280px) {
  .mob-img {
    margin-top: 13px;
  }
}

@media only screen and (min-width: 950px) and (max-width: 1099px) {
  .nav-items {
    margin-left: 1.5rem;
  }
}

.smlogo {
  height: 7rem;
  margin-top: -23px;
}

.supertext {
  border: 1px solid #d7d7d7;
  color: #000;
  font-size: 9px;
  font-weight: 500;
  top: -15px;
  right: 0px;
  padding: 4px;
}

.image-container {
  max-height: 87px;
  overflow: hidden;
  margin-bottom: 41px;
}

.mob-img {
  width: 225px;
}

.image-container-desk {
  max-height: 150px;
  /* overflow: hidden;
  margin-bottom: 41px; */
}