.carousel .control-dots{
  text-align: left;
  bottom: 10px;
  margin-left: 1rem;
}

.carousel .control-dots .dot.selected{
  background: #FCE402;
}

.carousel .control-dots .dot{
  background: #FFFFFF;
  opacity: 1;
  box-shadow: none;
}

.font-spectral{
  font-family: 'Spectral', serif;
}

.font-synthese-mob {
  font-family: 'synthese' !important;
  /* font-weight: 700 !important; */
}

@media (max-width: 320px) {
  .mobile-banner-heading1, .mobile-banner-heading2{
    font-size: 1.5rem;
  }
  .subscription-heading {
    font-size: 1rem;
  }
  .description-wrapper{
    font-size: 0.85rem;
  }
}

@media (max-width: 280px) {
  .mobile-banner-heading1, .mobile-banner-heading2{
    font-size: 1.25rem;
  }
  .subscription-heading {
    font-size: 0.75rem;
  }
  .description-wrapper{
    font-size: 0.65rem;
  }
}