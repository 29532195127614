.font-campaign-inspiration-plan {
  font-family: "campaign" !important;
}

.font-synthese-inspiration-plan {
  font-family: "synthese" !important;
  /* font-weight: 400 !important; */
}
.inspiration-wrapper {
  background-image: url("../../assets/hand2.png");
  background-size: 38%;
  background-repeat: no-repeat;
  /* background-position: 200% 30%; */
}

@media only screen and (max-width: 280px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2.png");
    background-size: 38%;
    background-repeat: no-repeat;
    background-position: 125% 15%;
  }
  .insp-tile-content {
    width: 7rem;
    height: 7rem;
  }
  .insp-tile-desc {
    width: 7rem;
  }
}

@media only screen and (min-width: 281px) and (max-width: 320px) {
  .insp-tile-content {
    width: 8rem !important;
    height: 8rem !important;
  }
  .insp-tile-desc {
    width: 8rem !important;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .insp-tile-content {
    width: 9.5rem !important;
    height: 9.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .insp-tile-desc {
    width: 9.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
  .insp-tile-content {
    width: 10rem !important;
    height: 10rem !important;
  }
  .insp-tile-desc {
    width: 10rem !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) {
  .insp-tile-content {
    width: 10rem !important;
    height: 10rem !important;
  }
  .insp-tile-desc {
    width: 10rem !important;
  }
}

@media only screen and (device-width: 540px) and (device-height: 720px) {
  .insp-tile-content {
    width: 14rem !important;
    height: 14rem !important;
  }
  .insp-tile-desc {
    width: 14rem !important;
  }
  .insp-play-media {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
  .insp-share-btn {
    width: 2.75rem !important;
    height: 2.25rem !important;
  }
}

@media (min-width: 281px) and (max-width: 420px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png");
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: 125% 8%;
  }
  .insp-tile-content {
    width: 11rem;
    height: 11rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .insp-tile-desc {
    width: 11rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 421px) and (max-width: 540px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png");
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: 125% 8%;
  }
  .insp-tile-content {
    width: 10rem;
    height: 10rem;
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
  }
  .insp-tile-desc {
    width: 10rem;
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
  }
}

@media only screen and (min-width: 541px) and (max-width: 767px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 40% !important;
    background-repeat: no-repeat !important;
    background-position: 125% 8% !important;
    padding-left: 5rem !important;
  }
  .start-wrapper {
    width: 60% !important;
  }
  .insp-tile-content {
    width: 8rem !important;
    height: 8rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .insp-tile-desc {
    width: 8rem;
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 40% !important;
    background-repeat: no-repeat !important;
    background-position: 125% 8% !important;
    padding-left: 5rem !important;
  }
  .start-wrapper {
    width: 60% !important;
  }
  .insp-tile-content {
    width: 8rem !important;
    height: 8rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .insp-tile-desc {
    width: 8rem;
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
  }
}

@media only screen and (min-width: 901px) and (max-width: 1100px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 40% !important;
    background-repeat: no-repeat !important;
    background-position: 125% 2% !important;
    padding-left: 5rem !important;
  }
  .start-wrapper {
    width: 60% !important;
  }
  .insp-tile-content {
    width: 9.5rem !important;
    height: 9.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .insp-tile-desc {
    width: 8rem;
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1300px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 36% !important;
    background-repeat: no-repeat !important;
    background-position: 125% 1% !important;
    padding-left: 5rem !important;
  }
  .start-wrapper {
    width: 60% !important;
  }
  .insp-tile-content {
    width: 12.5rem !important;
    height: 12.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .insp-tile-desc {
    width: 8rem;
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1366px) {
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 36% !important;
    background-repeat: no-repeat !important;
    background-position: 125% 1% !important;
    padding-left: 5rem !important;
  }
  .start-wrapper {
    width: 60% !important;
  }
  .insp-tile-content {
    width: 14rem !important;
    height: 14rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .insp-tile-desc {
    width: 8rem;
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
  }
}

@media only screen and (min-width: 640px) and (max-width: 800px) {
  .inspiration-wrapper {
    padding-right: 4% !important;
    padding-left: 4% !important;
  }
  .end-wrapper {
    width: 100% !important;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .inspiration-wrapper {
    padding-right: 12% !important;
    padding-left: 12% !important;
  }
  .end-wrapper {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .inspiration-wrapper {
    padding-right: 14% !important;
    padding-left: 14% !important;
  }
  .end-wrapper {
    width: 100% !important;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .inspiration-wrapper {
    padding-left: 3rem !important;
  }
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 45% !important;
    background-repeat: no-repeat !important;
    background-position: 140% 7%;
  }
  .start-wrapper {
    width: 50% !important;
  }
  .start-desc1,
  .start-desc2 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .end-wrapper {
    width: 90% !important;
  }
  .insp-tile-content {
    height: 9rem !important;
    width: 9rem !important;
  }
  .insp-tile-desc {
    width: 9rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .insp-play-media,
  .insp-share-btn {
    opacity: 100 !important;
  }
  .insp-play-media {
    width: 2rem !important;
    height: 2rem !important;
  }
  .insp-share-btn {
    width: 2.5rem !important;
    height: 2rem !important;
  }
  .tile-1,
  .tile-2,
  .tile-3,
  .tile-4,
  .tile-5,
  .tile-6,
  .tile-7,
  .tile-8,
  .tile-9,
  .tile-10,
  .tile-11,
  .tile-12 {
    align-items: unset !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .inspiration-wrapper {
    padding-left: 3rem !important;
  }
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 40% !important;
    background-repeat: no-repeat !important;
    background-position: 127% 4%;
  }
  .start-wrapper {
    width: 43% !important;
  }
  .start-desc1,
  .start-desc2 {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }
  .insp-tile-content {
    height: 11rem !important;
    width: 11rem !important;
  }
  .insp-tile-desc {
    width: 11rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .insp-play-media,
  .insp-share-btn {
    opacity: 100 !important;
  }
  .insp-play-media {
    width: 2rem !important;
    height: 2rem !important;
  }
  .insp-share-btn {
    width: 2.5rem !important;
    height: 2rem !important;
  }
  .tile-1,
  .tile-2,
  .tile-3,
  .tile-4,
  .tile-5,
  .tile-6,
  .tile-7,
  .tile-8,
  .tile-9,
  .tile-10,
  .tile-11,
  .tile-12 {
    align-items: unset !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: portrait) {
  .inspiration-wrapper {
    padding-left: 5rem !important;
  }
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 45% !important;
    background-repeat: no-repeat !important;
    background-position: 140% 0%;
  }
  .start-wrapper {
    width: 60% !important;
  }
  .start-desc1,
  .start-desc2 {
    font-size: 1.75rem !important;
    line-height: 2.35rem !important;
  }
  .end-wrapper {
    width: 90% !important;
  }
  .insp-tile-content {
    height: 11rem !important;
    width: 11rem !important;
  }
  .insp-tile-desc {
    width: 11rem !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
  .insp-play-media,
  .insp-share-btn {
    opacity: 100 !important;
  }
  .insp-play-media {
    width: 2rem !important;
    height: 2rem !important;
  }
  .insp-share-btn {
    width: 2.5rem !important;
    height: 2rem !important;
  }
  .description-para {
    font-size: medium !important;
    line-height: 1.25rem !important;
  }
  .tile-1,
  .tile-2,
  .tile-3,
  .tile-4,
  .tile-5,
  .tile-6,
  .tile-7,
  .tile-8,
  .tile-9,
  .tile-10,
  .tile-11,
  .tile-12 {
    align-items: unset !important;
  }
}
@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .inspiration-wrapper {
    padding-left: 12rem !important;
  }
  .inspiration-wrapper {
    background-image: url("../../assets/hand2-mobile.png") !important;
    background-size: 40% !important;
    background-repeat: no-repeat !important;
    background-position: 133% 0%;
  }
  .start-wrapper {
    width: 40% !important;
  }
  .start-desc1,
  .start-desc2 {
    font-size: 2rem !important;
    line-height: 2.35rem !important;
  }
  .end-wrapper {
    width: 90% !important;
  }
  .insp-tile-content {
    height: 13rem !important;
    width: 13rem !important;
  }
  .insp-tile-desc {
    width: 13rem !important;
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .insp-play-media,
  .insp-share-btn {
    opacity: 100 !important;
  }
  .insp-play-media {
    width: 2rem !important;
    height: 2rem !important;
  }
  .insp-share-btn {
    width: 2.5rem !important;
    height: 2rem !important;
  }
  .description-para {
    font-size: large !important;
    line-height: 1.5rem !important;
  }
  .tile-1,
  .tile-2,
  .tile-3,
  .tile-4,
  .tile-5,
  .tile-6,
  .tile-7,
  .tile-8,
  .tile-9,
  .tile-10,
  .tile-11,
  .tile-12 {
    align-items: unset !important;
  }
}
